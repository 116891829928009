import { default as editQ70LgcVJZDMeta } from "/codebuild/output/src911152362/src/pages/admin/account_plans/[account_plan_id]/edit.vue?macro=true";
import { default as indexiZ8giUFrRqMeta } from "/codebuild/output/src911152362/src/pages/admin/account_plans/[account_plan_id]/index.vue?macro=true";
import { default as _91account_plan_id_93bQdE7MdqcnMeta } from "/codebuild/output/src911152362/src/pages/admin/account_plans/[account_plan_id].vue?macro=true";
import { default as editTPLVCaigCCMeta } from "/codebuild/output/src911152362/src/pages/admin/account_requested_plans/[account_requested_plan_id]/edit.vue?macro=true";
import { default as index6Sgw4b61v9Meta } from "/codebuild/output/src911152362/src/pages/admin/account_requested_plans/[account_requested_plan_id]/index.vue?macro=true";
import { default as _91account_requested_plan_id_93iAuAtdamOeMeta } from "/codebuild/output/src911152362/src/pages/admin/account_requested_plans/[account_requested_plan_id].vue?macro=true";
import { default as indexyOJrKXKvU7Meta } from "/codebuild/output/src911152362/src/pages/admin/account_requested_plans/index.vue?macro=true";
import { default as newqDtEp0Co5RMeta } from "/codebuild/output/src911152362/src/pages/admin/account_requested_plans/new.vue?macro=true";
import { default as account_requested_plansXRxzxK5tpCMeta } from "/codebuild/output/src911152362/src/pages/admin/account_requested_plans.vue?macro=true";
import { default as editau8Alugyp3Meta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/edit.vue?macro=true";
import { default as indexSvAg4FgXnvMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/index.vue?macro=true";
import { default as _91account_requested_plan_id_93u9LBnEGIReMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id].vue?macro=true";
import { default as indexSjuH2Wfro2Meta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/account_requested_plans/index.vue?macro=true";
import { default as newyDT7uwQ4nKMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/account_requested_plans/new.vue?macro=true";
import { default as account_requested_plansP6VTCh95hIMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/account_requested_plans.vue?macro=true";
import { default as editJyrSR8PdsUMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/edit.vue?macro=true";
import { default as index2e4TCq1ZYSMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/index.vue?macro=true";
import { default as _91caller_id_id_93be4Ke6X7bLMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id].vue?macro=true";
import { default as indexOmzqTdfgNAMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/caller_ids/index.vue?macro=true";
import { default as newO6sZ3NGeuLMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/caller_ids/new.vue?macro=true";
import { default as caller_idsUOZneB9Bt1Meta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/caller_ids.vue?macro=true";
import { default as editrShBY3vRqNMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/edit.vue?macro=true";
import { default as indexoIins6glQWMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/index.vue?macro=true";
import { default as edit8bVIYtSlDSMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/edit.vue?macro=true";
import { default as indextS10szHLMIMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/index.vue?macro=true";
import { default as _91sms_masking_id_93tc6j85FeR5Meta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id].vue?macro=true";
import { default as indexHbadiL8IPRMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/sms_maskings/index.vue?macro=true";
import { default as newDsSCsc4JAOMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/sms_maskings/new.vue?macro=true";
import { default as sms_maskingsTuHOwQkEGhMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/sms_maskings.vue?macro=true";
import { default as _91account_id_933dpkrqUn2QMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts/[account_id].vue?macro=true";
import { default as indexRno9ktUo4CMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts/index.vue?macro=true";
import { default as wizard_plan_selectionESBbnq8ucaMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts/wizard_plan_selection.vue?macro=true";
import { default as wizardD8qbHgW9QmMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts/wizard.vue?macro=true";
import { default as accounts6zISMYn1ROMeta } from "/codebuild/output/src911152362/src/pages/admin/accounts.vue?macro=true";
import { default as edit6PuuLwlVXGMeta } from "/codebuild/output/src911152362/src/pages/admin/admin_users/[admin_user_id]/edit.vue?macro=true";
import { default as indexQMEfa6uMshMeta } from "/codebuild/output/src911152362/src/pages/admin/admin_users/[admin_user_id]/index.vue?macro=true";
import { default as _91admin_user_id_93GnDHzh1gV3Meta } from "/codebuild/output/src911152362/src/pages/admin/admin_users/[admin_user_id].vue?macro=true";
import { default as indexehlHH6YkW5Meta } from "/codebuild/output/src911152362/src/pages/admin/admin_users/index.vue?macro=true";
import { default as new78Sqbj1blZMeta } from "/codebuild/output/src911152362/src/pages/admin/admin_users/new.vue?macro=true";
import { default as admin_usersai7I49VFr2Meta } from "/codebuild/output/src911152362/src/pages/admin/admin_users.vue?macro=true";
import { default as index8MdSMxugpRMeta } from "/codebuild/output/src911152362/src/pages/admin/audit_trails/index.vue?macro=true";
import { default as audit_trailsElLb3A27biMeta } from "/codebuild/output/src911152362/src/pages/admin/audit_trails.vue?macro=true";
import { default as edit0jJAzsqTVXMeta } from "/codebuild/output/src911152362/src/pages/admin/billings/[billing_id]/edit.vue?macro=true";
import { default as indexqYXUcnZyOzMeta } from "/codebuild/output/src911152362/src/pages/admin/billings/[billing_id]/index.vue?macro=true";
import { default as _91billing_id_93JanJrMJa88Meta } from "/codebuild/output/src911152362/src/pages/admin/billings/[billing_id].vue?macro=true";
import { default as indexnCe3suusSqMeta } from "/codebuild/output/src911152362/src/pages/admin/billings/index.vue?macro=true";
import { default as newVeh7XQ29wAMeta } from "/codebuild/output/src911152362/src/pages/admin/billings/new.vue?macro=true";
import { default as billingstmdj9RebhBMeta } from "/codebuild/output/src911152362/src/pages/admin/billings.vue?macro=true";
import { default as editpFePewsI4XMeta } from "/codebuild/output/src911152362/src/pages/admin/caller_ids/[caller_id_id]/edit.vue?macro=true";
import { default as index7B1Nf15hUvMeta } from "/codebuild/output/src911152362/src/pages/admin/caller_ids/[caller_id_id]/index.vue?macro=true";
import { default as _91caller_id_id_93YfBkVpBRMHMeta } from "/codebuild/output/src911152362/src/pages/admin/caller_ids/[caller_id_id].vue?macro=true";
import { default as indexaMnWfhO2a0Meta } from "/codebuild/output/src911152362/src/pages/admin/caller_ids/index.vue?macro=true";
import { default as newX5eDlID2pVMeta } from "/codebuild/output/src911152362/src/pages/admin/caller_ids/new.vue?macro=true";
import { default as caller_idsg1n6aRyWMqMeta } from "/codebuild/output/src911152362/src/pages/admin/caller_ids.vue?macro=true";
import { default as editRGZzn7WdOVMeta } from "/codebuild/output/src911152362/src/pages/admin/campaign_settings/[campaign_setting_id]/edit.vue?macro=true";
import { default as indexHIH73hYLhyMeta } from "/codebuild/output/src911152362/src/pages/admin/campaign_settings/[campaign_setting_id]/index.vue?macro=true";
import { default as _91campaign_setting_id_93N0hd1UPpTzMeta } from "/codebuild/output/src911152362/src/pages/admin/campaign_settings/[campaign_setting_id].vue?macro=true";
import { default as editQL393HgcAeMeta } from "/codebuild/output/src911152362/src/pages/admin/campaigns/[campaign_id]/edit.vue?macro=true";
import { default as indexX6N8XAJWMNMeta } from "/codebuild/output/src911152362/src/pages/admin/campaigns/[campaign_id]/index.vue?macro=true";
import { default as editTXYhApGbC3Meta } from "/codebuild/output/src911152362/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/edit.vue?macro=true";
import { default as indexp562C8mTtIMeta } from "/codebuild/output/src911152362/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/index.vue?macro=true";
import { default as _91outbound_call_transaction_id_935pJaqAW9YAMeta } from "/codebuild/output/src911152362/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id].vue?macro=true";
import { default as index2Sv5pEcc5XMeta } from "/codebuild/output/src911152362/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/index.vue?macro=true";
import { default as newuc3cICnmvzMeta } from "/codebuild/output/src911152362/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/new.vue?macro=true";
import { default as outbound_call_transactions2KouUhrV4eMeta } from "/codebuild/output/src911152362/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions.vue?macro=true";
import { default as _91campaign_id_930ZmbTc9N6vMeta } from "/codebuild/output/src911152362/src/pages/admin/campaigns/[campaign_id].vue?macro=true";
import { default as indexLWSTGRllTXMeta } from "/codebuild/output/src911152362/src/pages/admin/campaigns/index.vue?macro=true";
import { default as newponjh89fhcMeta } from "/codebuild/output/src911152362/src/pages/admin/campaigns/new.vue?macro=true";
import { default as campaignsYa0reCUBrnMeta } from "/codebuild/output/src911152362/src/pages/admin/campaigns.vue?macro=true";
import { default as indexHZHjjyW9uvMeta } from "/codebuild/output/src911152362/src/pages/admin/custom_emails/[custom_email_id]/index.vue?macro=true";
import { default as _91custom_email_id_93gdYpOUbtb9Meta } from "/codebuild/output/src911152362/src/pages/admin/custom_emails/[custom_email_id].vue?macro=true";
import { default as indexfFQB30P7TFMeta } from "/codebuild/output/src911152362/src/pages/admin/custom_emails/index.vue?macro=true";
import { default as newpqLHhdzZvHMeta } from "/codebuild/output/src911152362/src/pages/admin/custom_emails/new.vue?macro=true";
import { default as custom_emailsIo6MyuwsOnMeta } from "/codebuild/output/src911152362/src/pages/admin/custom_emails.vue?macro=true";
import { default as indexCdx0wI9TxwMeta } from "/codebuild/output/src911152362/src/pages/admin/dashboard/index.vue?macro=true";
import { default as dashboardEQeLOEIXIbMeta } from "/codebuild/output/src911152362/src/pages/admin/dashboard.vue?macro=true";
import { default as indexnga7uBzbxpMeta } from "/codebuild/output/src911152362/src/pages/admin/inquiries/[inquiry_id]/index.vue?macro=true";
import { default as _91inquiry_id_93Oh9XjLhdQPMeta } from "/codebuild/output/src911152362/src/pages/admin/inquiries/[inquiry_id].vue?macro=true";
import { default as indexK6oTw8yu8WMeta } from "/codebuild/output/src911152362/src/pages/admin/inquiries/index.vue?macro=true";
import { default as newoWaE44deOzMeta } from "/codebuild/output/src911152362/src/pages/admin/inquiries/new.vue?macro=true";
import { default as inquiriesSlliv5ep6GMeta } from "/codebuild/output/src911152362/src/pages/admin/inquiries.vue?macro=true";
import { default as indexNn7VgZMENEMeta } from "/codebuild/output/src911152362/src/pages/admin/login/index.vue?macro=true";
import { default as login6qasTF7S6vMeta } from "/codebuild/output/src911152362/src/pages/admin/login.vue?macro=true";
import { default as newfbaBM7VB6vMeta } from "/codebuild/output/src911152362/src/pages/admin/password/new.vue?macro=true";
import { default as indexroO9fM4CmoMeta } from "/codebuild/output/src911152362/src/pages/admin/reports/index.vue?macro=true";
import { default as reportseDZA6Nf1iEMeta } from "/codebuild/output/src911152362/src/pages/admin/reports.vue?macro=true";
import { default as edit2rK0hMRZiYMeta } from "/codebuild/output/src911152362/src/pages/admin/sms_maskings/[sms_masking_id]/edit.vue?macro=true";
import { default as indexnTLPrdzkfxMeta } from "/codebuild/output/src911152362/src/pages/admin/sms_maskings/[sms_masking_id]/index.vue?macro=true";
import { default as _91sms_masking_id_934ZOZ95SvS7Meta } from "/codebuild/output/src911152362/src/pages/admin/sms_maskings/[sms_masking_id].vue?macro=true";
import { default as indexSUv7v3VZTDMeta } from "/codebuild/output/src911152362/src/pages/admin/sms_maskings/index.vue?macro=true";
import { default as newdVh5J2XmVUMeta } from "/codebuild/output/src911152362/src/pages/admin/sms_maskings/new.vue?macro=true";
import { default as sms_maskingsCndk3CYoQPMeta } from "/codebuild/output/src911152362/src/pages/admin/sms_maskings.vue?macro=true";
import { default as edit9tjn17zxxAMeta } from "/codebuild/output/src911152362/src/pages/admin/users/[user_id]/edit.vue?macro=true";
import { default as indexvXdHnfVN2NMeta } from "/codebuild/output/src911152362/src/pages/admin/users/[user_id]/index.vue?macro=true";
import { default as _91user_id_93CuP8u6m6knMeta } from "/codebuild/output/src911152362/src/pages/admin/users/[user_id].vue?macro=true";
import { default as indexxEF3ulN06XMeta } from "/codebuild/output/src911152362/src/pages/admin/users/index.vue?macro=true";
import { default as newASQDLUfOKiMeta } from "/codebuild/output/src911152362/src/pages/admin/users/new.vue?macro=true";
import { default as userspdxOT5yhrrMeta } from "/codebuild/output/src911152362/src/pages/admin/users.vue?macro=true";
import { default as editoidcdC7VaeMeta } from "/codebuild/output/src911152362/src/pages/admin/wallets/[wallet_id]/edit.vue?macro=true";
import { default as indexTRvdwlBROUMeta } from "/codebuild/output/src911152362/src/pages/admin/wallets/[wallet_id]/index.vue?macro=true";
import { default as _91wallet_id_932Fh33jGuivMeta } from "/codebuild/output/src911152362/src/pages/admin/wallets/[wallet_id].vue?macro=true";
import { default as adminRaRRMfciQLMeta } from "/codebuild/output/src911152362/src/pages/admin.vue?macro=true";
import { default as indexDy9fq1OoDTMeta } from "/codebuild/output/src911152362/src/pages/audit_logs/index.vue?macro=true";
import { default as indexSCtNdkExWnMeta } from "/codebuild/output/src911152362/src/pages/billings/index.vue?macro=true";
import { default as indexcSfQNZBp6SMeta } from "/codebuild/output/src911152362/src/pages/call_out/reports/index.vue?macro=true";
import { default as _91id_93cDQ8nJy7TvMeta } from "/codebuild/output/src911152362/src/pages/call_recordings/[id].vue?macro=true";
import { default as indexs6RU4LYBB3Meta } from "/codebuild/output/src911152362/src/pages/campaigns/[id]/index.vue?macro=true";
import { default as indexlqJG3LiI9gMeta } from "/codebuild/output/src911152362/src/pages/campaigns/index.vue?macro=true";
import { default as indexeEj7JLFXoYMeta } from "/codebuild/output/src911152362/src/pages/dashboard/index.vue?macro=true";
import { default as index3rZpulFL4MMeta } from "/codebuild/output/src911152362/src/pages/help/index.vue?macro=true";
import { default as indexnmUBVPIefKMeta } from "/codebuild/output/src911152362/src/pages/index.vue?macro=true";
import { default as index0ugBIpToT2Meta } from "/codebuild/output/src911152362/src/pages/inquiry/index.vue?macro=true";
import { default as editorLbLb4iXDxXMeta } from "/codebuild/output/src911152362/src/pages/ivr_trees/[id]/editor.vue?macro=true";
import { default as indexpj4CalD24iMeta } from "/codebuild/output/src911152362/src/pages/ivr_trees/index.vue?macro=true";
import { default as verify_emailNnfmJV8kghMeta } from "/codebuild/output/src911152362/src/pages/profiles/[user_id]/verify_email.vue?macro=true";
import { default as welcome_dashboardlhbxNDsihNMeta } from "/codebuild/output/src911152362/src/pages/profiles/[user_id]/welcome_dashboard.vue?macro=true";
import { default as indexOC0GlM4he2Meta } from "/codebuild/output/src911152362/src/pages/profiles/index.vue?macro=true";
import { default as indexzdPxEGyO3WMeta } from "/codebuild/output/src911152362/src/pages/sub_users/users/index.vue?macro=true";
import { default as index5vrcmXDBJcMeta } from "/codebuild/output/src911152362/src/pages/terms_and_conditions/index.vue?macro=true";
import { default as indexPGlvfaK1RlMeta } from "/codebuild/output/src911152362/src/pages/users/password/edit/index.vue?macro=true";
import { default as indexiYxm4CmyOnMeta } from "/codebuild/output/src911152362/src/pages/users/password/new/index.vue?macro=true";
import { default as indexgXUJd5SG2NMeta } from "/codebuild/output/src911152362/src/pages/users/sign_in/index.vue?macro=true";
export default [
  {
    name: adminRaRRMfciQLMeta?.name ?? "admin",
    path: adminRaRRMfciQLMeta?.path ?? "/admin",
    children: [
  {
    path: _91account_plan_id_93bQdE7MdqcnMeta?.path ?? "account_plans/:account_plan_id()",
    children: [
  {
    name: editQ70LgcVJZDMeta?.name ?? "admin-account_plans-account_plan_id-edit",
    path: editQ70LgcVJZDMeta?.path ?? "edit",
    meta: editQ70LgcVJZDMeta || {},
    alias: editQ70LgcVJZDMeta?.alias || [],
    redirect: editQ70LgcVJZDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/account_plans/[account_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexiZ8giUFrRqMeta?.name ?? "admin-account_plans-account_plan_id",
    path: indexiZ8giUFrRqMeta?.path ?? "",
    meta: indexiZ8giUFrRqMeta || {},
    alias: indexiZ8giUFrRqMeta?.alias || [],
    redirect: indexiZ8giUFrRqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/account_plans/[account_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_plan_id_93bQdE7MdqcnMeta?.name ?? undefined,
    meta: _91account_plan_id_93bQdE7MdqcnMeta || {},
    alias: _91account_plan_id_93bQdE7MdqcnMeta?.alias || [],
    redirect: _91account_plan_id_93bQdE7MdqcnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/account_plans/[account_plan_id].vue").then(m => m.default || m)
  },
  {
    path: account_requested_plansXRxzxK5tpCMeta?.path ?? "account_requested_plans",
    children: [
  {
    path: _91account_requested_plan_id_93iAuAtdamOeMeta?.path ?? ":account_requested_plan_id()",
    children: [
  {
    name: editTPLVCaigCCMeta?.name ?? "admin-account_requested_plans-account_requested_plan_id-edit",
    path: editTPLVCaigCCMeta?.path ?? "edit",
    meta: editTPLVCaigCCMeta || {},
    alias: editTPLVCaigCCMeta?.alias || [],
    redirect: editTPLVCaigCCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/account_requested_plans/[account_requested_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index6Sgw4b61v9Meta?.name ?? "admin-account_requested_plans-account_requested_plan_id",
    path: index6Sgw4b61v9Meta?.path ?? "",
    meta: index6Sgw4b61v9Meta || {},
    alias: index6Sgw4b61v9Meta?.alias || [],
    redirect: index6Sgw4b61v9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/account_requested_plans/[account_requested_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_requested_plan_id_93iAuAtdamOeMeta?.name ?? undefined,
    meta: _91account_requested_plan_id_93iAuAtdamOeMeta || {},
    alias: _91account_requested_plan_id_93iAuAtdamOeMeta?.alias || [],
    redirect: _91account_requested_plan_id_93iAuAtdamOeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/account_requested_plans/[account_requested_plan_id].vue").then(m => m.default || m)
  },
  {
    name: indexyOJrKXKvU7Meta?.name ?? "admin-account_requested_plans",
    path: indexyOJrKXKvU7Meta?.path ?? "",
    meta: indexyOJrKXKvU7Meta || {},
    alias: indexyOJrKXKvU7Meta?.alias || [],
    redirect: indexyOJrKXKvU7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/account_requested_plans/index.vue").then(m => m.default || m)
  },
  {
    name: newqDtEp0Co5RMeta?.name ?? "admin-account_requested_plans-new",
    path: newqDtEp0Co5RMeta?.path ?? "new",
    meta: newqDtEp0Co5RMeta || {},
    alias: newqDtEp0Co5RMeta?.alias || [],
    redirect: newqDtEp0Co5RMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/account_requested_plans/new.vue").then(m => m.default || m)
  }
],
    name: account_requested_plansXRxzxK5tpCMeta?.name ?? undefined,
    meta: account_requested_plansXRxzxK5tpCMeta || {},
    alias: account_requested_plansXRxzxK5tpCMeta?.alias || [],
    redirect: account_requested_plansXRxzxK5tpCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/account_requested_plans.vue").then(m => m.default || m)
  },
  {
    path: accounts6zISMYn1ROMeta?.path ?? "accounts",
    children: [
  {
    path: _91account_id_933dpkrqUn2QMeta?.path ?? ":account_id()",
    children: [
  {
    path: account_requested_plansP6VTCh95hIMeta?.path ?? "account_requested_plans",
    children: [
  {
    path: _91account_requested_plan_id_93u9LBnEGIReMeta?.path ?? ":account_requested_plan_id()",
    children: [
  {
    name: editau8Alugyp3Meta?.name ?? "admin-accounts-account_id-account_requested_plans-account_requested_plan_id-edit",
    path: editau8Alugyp3Meta?.path ?? "edit",
    meta: editau8Alugyp3Meta || {},
    alias: editau8Alugyp3Meta?.alias || [],
    redirect: editau8Alugyp3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexSvAg4FgXnvMeta?.name ?? "admin-accounts-account_id-account_requested_plans-account_requested_plan_id",
    path: indexSvAg4FgXnvMeta?.path ?? "",
    meta: indexSvAg4FgXnvMeta || {},
    alias: indexSvAg4FgXnvMeta?.alias || [],
    redirect: indexSvAg4FgXnvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_requested_plan_id_93u9LBnEGIReMeta?.name ?? undefined,
    meta: _91account_requested_plan_id_93u9LBnEGIReMeta || {},
    alias: _91account_requested_plan_id_93u9LBnEGIReMeta?.alias || [],
    redirect: _91account_requested_plan_id_93u9LBnEGIReMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id].vue").then(m => m.default || m)
  },
  {
    name: indexSjuH2Wfro2Meta?.name ?? "admin-accounts-account_id-account_requested_plans",
    path: indexSjuH2Wfro2Meta?.path ?? "",
    meta: indexSjuH2Wfro2Meta || {},
    alias: indexSjuH2Wfro2Meta?.alias || [],
    redirect: indexSjuH2Wfro2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/account_requested_plans/index.vue").then(m => m.default || m)
  },
  {
    name: newyDT7uwQ4nKMeta?.name ?? "admin-accounts-account_id-account_requested_plans-new",
    path: newyDT7uwQ4nKMeta?.path ?? "new",
    meta: newyDT7uwQ4nKMeta || {},
    alias: newyDT7uwQ4nKMeta?.alias || [],
    redirect: newyDT7uwQ4nKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/account_requested_plans/new.vue").then(m => m.default || m)
  }
],
    name: account_requested_plansP6VTCh95hIMeta?.name ?? undefined,
    meta: account_requested_plansP6VTCh95hIMeta || {},
    alias: account_requested_plansP6VTCh95hIMeta?.alias || [],
    redirect: account_requested_plansP6VTCh95hIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/account_requested_plans.vue").then(m => m.default || m)
  },
  {
    path: caller_idsUOZneB9Bt1Meta?.path ?? "caller_ids",
    children: [
  {
    path: _91caller_id_id_93be4Ke6X7bLMeta?.path ?? ":caller_id_id()",
    children: [
  {
    name: editJyrSR8PdsUMeta?.name ?? "admin-accounts-account_id-caller_ids-caller_id_id-edit",
    path: editJyrSR8PdsUMeta?.path ?? "edit",
    meta: editJyrSR8PdsUMeta || {},
    alias: editJyrSR8PdsUMeta?.alias || [],
    redirect: editJyrSR8PdsUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index2e4TCq1ZYSMeta?.name ?? "admin-accounts-account_id-caller_ids-caller_id_id",
    path: index2e4TCq1ZYSMeta?.path ?? "",
    meta: index2e4TCq1ZYSMeta || {},
    alias: index2e4TCq1ZYSMeta?.alias || [],
    redirect: index2e4TCq1ZYSMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91caller_id_id_93be4Ke6X7bLMeta?.name ?? undefined,
    meta: _91caller_id_id_93be4Ke6X7bLMeta || {},
    alias: _91caller_id_id_93be4Ke6X7bLMeta?.alias || [],
    redirect: _91caller_id_id_93be4Ke6X7bLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id].vue").then(m => m.default || m)
  },
  {
    name: indexOmzqTdfgNAMeta?.name ?? "admin-accounts-account_id-caller_ids",
    path: indexOmzqTdfgNAMeta?.path ?? "",
    meta: indexOmzqTdfgNAMeta || {},
    alias: indexOmzqTdfgNAMeta?.alias || [],
    redirect: indexOmzqTdfgNAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/caller_ids/index.vue").then(m => m.default || m)
  },
  {
    name: newO6sZ3NGeuLMeta?.name ?? "admin-accounts-account_id-caller_ids-new",
    path: newO6sZ3NGeuLMeta?.path ?? "new",
    meta: newO6sZ3NGeuLMeta || {},
    alias: newO6sZ3NGeuLMeta?.alias || [],
    redirect: newO6sZ3NGeuLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/caller_ids/new.vue").then(m => m.default || m)
  }
],
    name: caller_idsUOZneB9Bt1Meta?.name ?? undefined,
    meta: caller_idsUOZneB9Bt1Meta || {},
    alias: caller_idsUOZneB9Bt1Meta?.alias || [],
    redirect: caller_idsUOZneB9Bt1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/caller_ids.vue").then(m => m.default || m)
  },
  {
    name: editrShBY3vRqNMeta?.name ?? "admin-accounts-account_id-edit",
    path: editrShBY3vRqNMeta?.path ?? "edit",
    meta: editrShBY3vRqNMeta || {},
    alias: editrShBY3vRqNMeta?.alias || [],
    redirect: editrShBY3vRqNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexoIins6glQWMeta?.name ?? "admin-accounts-account_id",
    path: indexoIins6glQWMeta?.path ?? "",
    meta: indexoIins6glQWMeta || {},
    alias: indexoIins6glQWMeta?.alias || [],
    redirect: indexoIins6glQWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/index.vue").then(m => m.default || m)
  },
  {
    path: sms_maskingsTuHOwQkEGhMeta?.path ?? "sms_maskings",
    children: [
  {
    path: _91sms_masking_id_93tc6j85FeR5Meta?.path ?? ":sms_masking_id()",
    children: [
  {
    name: edit8bVIYtSlDSMeta?.name ?? "admin-accounts-account_id-sms_maskings-sms_masking_id-edit",
    path: edit8bVIYtSlDSMeta?.path ?? "edit",
    meta: edit8bVIYtSlDSMeta || {},
    alias: edit8bVIYtSlDSMeta?.alias || [],
    redirect: edit8bVIYtSlDSMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indextS10szHLMIMeta?.name ?? "admin-accounts-account_id-sms_maskings-sms_masking_id",
    path: indextS10szHLMIMeta?.path ?? "",
    meta: indextS10szHLMIMeta || {},
    alias: indextS10szHLMIMeta?.alias || [],
    redirect: indextS10szHLMIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91sms_masking_id_93tc6j85FeR5Meta?.name ?? undefined,
    meta: _91sms_masking_id_93tc6j85FeR5Meta || {},
    alias: _91sms_masking_id_93tc6j85FeR5Meta?.alias || [],
    redirect: _91sms_masking_id_93tc6j85FeR5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id].vue").then(m => m.default || m)
  },
  {
    name: indexHbadiL8IPRMeta?.name ?? "admin-accounts-account_id-sms_maskings",
    path: indexHbadiL8IPRMeta?.path ?? "",
    meta: indexHbadiL8IPRMeta || {},
    alias: indexHbadiL8IPRMeta?.alias || [],
    redirect: indexHbadiL8IPRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/sms_maskings/index.vue").then(m => m.default || m)
  },
  {
    name: newDsSCsc4JAOMeta?.name ?? "admin-accounts-account_id-sms_maskings-new",
    path: newDsSCsc4JAOMeta?.path ?? "new",
    meta: newDsSCsc4JAOMeta || {},
    alias: newDsSCsc4JAOMeta?.alias || [],
    redirect: newDsSCsc4JAOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/sms_maskings/new.vue").then(m => m.default || m)
  }
],
    name: sms_maskingsTuHOwQkEGhMeta?.name ?? undefined,
    meta: sms_maskingsTuHOwQkEGhMeta || {},
    alias: sms_maskingsTuHOwQkEGhMeta?.alias || [],
    redirect: sms_maskingsTuHOwQkEGhMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id]/sms_maskings.vue").then(m => m.default || m)
  }
],
    name: _91account_id_933dpkrqUn2QMeta?.name ?? undefined,
    meta: _91account_id_933dpkrqUn2QMeta || {},
    alias: _91account_id_933dpkrqUn2QMeta?.alias || [],
    redirect: _91account_id_933dpkrqUn2QMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/[account_id].vue").then(m => m.default || m)
  },
  {
    name: indexRno9ktUo4CMeta?.name ?? "admin-accounts",
    path: indexRno9ktUo4CMeta?.path ?? "",
    meta: indexRno9ktUo4CMeta || {},
    alias: indexRno9ktUo4CMeta?.alias || [],
    redirect: indexRno9ktUo4CMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: wizard_plan_selectionESBbnq8ucaMeta?.name ?? "admin-accounts-wizard_plan_selection",
    path: wizard_plan_selectionESBbnq8ucaMeta?.path ?? "wizard_plan_selection",
    meta: wizard_plan_selectionESBbnq8ucaMeta || {},
    alias: wizard_plan_selectionESBbnq8ucaMeta?.alias || [],
    redirect: wizard_plan_selectionESBbnq8ucaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/wizard_plan_selection.vue").then(m => m.default || m)
  },
  {
    name: wizardD8qbHgW9QmMeta?.name ?? "admin-accounts-wizard",
    path: wizardD8qbHgW9QmMeta?.path ?? "wizard",
    meta: wizardD8qbHgW9QmMeta || {},
    alias: wizardD8qbHgW9QmMeta?.alias || [],
    redirect: wizardD8qbHgW9QmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts/wizard.vue").then(m => m.default || m)
  }
],
    name: accounts6zISMYn1ROMeta?.name ?? undefined,
    meta: accounts6zISMYn1ROMeta || {},
    alias: accounts6zISMYn1ROMeta?.alias || [],
    redirect: accounts6zISMYn1ROMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/accounts.vue").then(m => m.default || m)
  },
  {
    path: admin_usersai7I49VFr2Meta?.path ?? "admin_users",
    children: [
  {
    path: _91admin_user_id_93GnDHzh1gV3Meta?.path ?? ":admin_user_id()",
    children: [
  {
    name: edit6PuuLwlVXGMeta?.name ?? "admin-admin_users-admin_user_id-edit",
    path: edit6PuuLwlVXGMeta?.path ?? "edit",
    meta: edit6PuuLwlVXGMeta || {},
    alias: edit6PuuLwlVXGMeta?.alias || [],
    redirect: edit6PuuLwlVXGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/admin_users/[admin_user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexQMEfa6uMshMeta?.name ?? "admin-admin_users-admin_user_id",
    path: indexQMEfa6uMshMeta?.path ?? "",
    meta: indexQMEfa6uMshMeta || {},
    alias: indexQMEfa6uMshMeta?.alias || [],
    redirect: indexQMEfa6uMshMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/admin_users/[admin_user_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91admin_user_id_93GnDHzh1gV3Meta?.name ?? undefined,
    meta: _91admin_user_id_93GnDHzh1gV3Meta || {},
    alias: _91admin_user_id_93GnDHzh1gV3Meta?.alias || [],
    redirect: _91admin_user_id_93GnDHzh1gV3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/admin_users/[admin_user_id].vue").then(m => m.default || m)
  },
  {
    name: indexehlHH6YkW5Meta?.name ?? "admin-admin_users",
    path: indexehlHH6YkW5Meta?.path ?? "",
    meta: indexehlHH6YkW5Meta || {},
    alias: indexehlHH6YkW5Meta?.alias || [],
    redirect: indexehlHH6YkW5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/admin_users/index.vue").then(m => m.default || m)
  },
  {
    name: new78Sqbj1blZMeta?.name ?? "admin-admin_users-new",
    path: new78Sqbj1blZMeta?.path ?? "new",
    meta: new78Sqbj1blZMeta || {},
    alias: new78Sqbj1blZMeta?.alias || [],
    redirect: new78Sqbj1blZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/admin_users/new.vue").then(m => m.default || m)
  }
],
    name: admin_usersai7I49VFr2Meta?.name ?? undefined,
    meta: admin_usersai7I49VFr2Meta || {},
    alias: admin_usersai7I49VFr2Meta?.alias || [],
    redirect: admin_usersai7I49VFr2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/admin_users.vue").then(m => m.default || m)
  },
  {
    path: audit_trailsElLb3A27biMeta?.path ?? "audit_trails",
    children: [
  {
    name: index8MdSMxugpRMeta?.name ?? "admin-audit_trails",
    path: index8MdSMxugpRMeta?.path ?? "",
    meta: index8MdSMxugpRMeta || {},
    alias: index8MdSMxugpRMeta?.alias || [],
    redirect: index8MdSMxugpRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/audit_trails/index.vue").then(m => m.default || m)
  }
],
    name: audit_trailsElLb3A27biMeta?.name ?? undefined,
    meta: audit_trailsElLb3A27biMeta || {},
    alias: audit_trailsElLb3A27biMeta?.alias || [],
    redirect: audit_trailsElLb3A27biMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/audit_trails.vue").then(m => m.default || m)
  },
  {
    path: billingstmdj9RebhBMeta?.path ?? "billings",
    children: [
  {
    path: _91billing_id_93JanJrMJa88Meta?.path ?? ":billing_id()",
    children: [
  {
    name: edit0jJAzsqTVXMeta?.name ?? "admin-billings-billing_id-edit",
    path: edit0jJAzsqTVXMeta?.path ?? "edit",
    meta: edit0jJAzsqTVXMeta || {},
    alias: edit0jJAzsqTVXMeta?.alias || [],
    redirect: edit0jJAzsqTVXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/billings/[billing_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexqYXUcnZyOzMeta?.name ?? "admin-billings-billing_id",
    path: indexqYXUcnZyOzMeta?.path ?? "",
    meta: indexqYXUcnZyOzMeta || {},
    alias: indexqYXUcnZyOzMeta?.alias || [],
    redirect: indexqYXUcnZyOzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/billings/[billing_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91billing_id_93JanJrMJa88Meta?.name ?? undefined,
    meta: _91billing_id_93JanJrMJa88Meta || {},
    alias: _91billing_id_93JanJrMJa88Meta?.alias || [],
    redirect: _91billing_id_93JanJrMJa88Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/billings/[billing_id].vue").then(m => m.default || m)
  },
  {
    name: indexnCe3suusSqMeta?.name ?? "admin-billings",
    path: indexnCe3suusSqMeta?.path ?? "",
    meta: indexnCe3suusSqMeta || {},
    alias: indexnCe3suusSqMeta?.alias || [],
    redirect: indexnCe3suusSqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/billings/index.vue").then(m => m.default || m)
  },
  {
    name: newVeh7XQ29wAMeta?.name ?? "admin-billings-new",
    path: newVeh7XQ29wAMeta?.path ?? "new",
    meta: newVeh7XQ29wAMeta || {},
    alias: newVeh7XQ29wAMeta?.alias || [],
    redirect: newVeh7XQ29wAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/billings/new.vue").then(m => m.default || m)
  }
],
    name: billingstmdj9RebhBMeta?.name ?? undefined,
    meta: billingstmdj9RebhBMeta || {},
    alias: billingstmdj9RebhBMeta?.alias || [],
    redirect: billingstmdj9RebhBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/billings.vue").then(m => m.default || m)
  },
  {
    path: caller_idsg1n6aRyWMqMeta?.path ?? "caller_ids",
    children: [
  {
    path: _91caller_id_id_93YfBkVpBRMHMeta?.path ?? ":caller_id_id()",
    children: [
  {
    name: editpFePewsI4XMeta?.name ?? "admin-caller_ids-caller_id_id-edit",
    path: editpFePewsI4XMeta?.path ?? "edit",
    meta: editpFePewsI4XMeta || {},
    alias: editpFePewsI4XMeta?.alias || [],
    redirect: editpFePewsI4XMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/caller_ids/[caller_id_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index7B1Nf15hUvMeta?.name ?? "admin-caller_ids-caller_id_id",
    path: index7B1Nf15hUvMeta?.path ?? "",
    meta: index7B1Nf15hUvMeta || {},
    alias: index7B1Nf15hUvMeta?.alias || [],
    redirect: index7B1Nf15hUvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/caller_ids/[caller_id_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91caller_id_id_93YfBkVpBRMHMeta?.name ?? undefined,
    meta: _91caller_id_id_93YfBkVpBRMHMeta || {},
    alias: _91caller_id_id_93YfBkVpBRMHMeta?.alias || [],
    redirect: _91caller_id_id_93YfBkVpBRMHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/caller_ids/[caller_id_id].vue").then(m => m.default || m)
  },
  {
    name: indexaMnWfhO2a0Meta?.name ?? "admin-caller_ids",
    path: indexaMnWfhO2a0Meta?.path ?? "",
    meta: indexaMnWfhO2a0Meta || {},
    alias: indexaMnWfhO2a0Meta?.alias || [],
    redirect: indexaMnWfhO2a0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/caller_ids/index.vue").then(m => m.default || m)
  },
  {
    name: newX5eDlID2pVMeta?.name ?? "admin-caller_ids-new",
    path: newX5eDlID2pVMeta?.path ?? "new",
    meta: newX5eDlID2pVMeta || {},
    alias: newX5eDlID2pVMeta?.alias || [],
    redirect: newX5eDlID2pVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/caller_ids/new.vue").then(m => m.default || m)
  }
],
    name: caller_idsg1n6aRyWMqMeta?.name ?? undefined,
    meta: caller_idsg1n6aRyWMqMeta || {},
    alias: caller_idsg1n6aRyWMqMeta?.alias || [],
    redirect: caller_idsg1n6aRyWMqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/caller_ids.vue").then(m => m.default || m)
  },
  {
    path: _91campaign_setting_id_93N0hd1UPpTzMeta?.path ?? "campaign_settings/:campaign_setting_id()",
    children: [
  {
    name: editRGZzn7WdOVMeta?.name ?? "admin-campaign_settings-campaign_setting_id-edit",
    path: editRGZzn7WdOVMeta?.path ?? "edit",
    meta: editRGZzn7WdOVMeta || {},
    alias: editRGZzn7WdOVMeta?.alias || [],
    redirect: editRGZzn7WdOVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/campaign_settings/[campaign_setting_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexHIH73hYLhyMeta?.name ?? "admin-campaign_settings-campaign_setting_id",
    path: indexHIH73hYLhyMeta?.path ?? "",
    meta: indexHIH73hYLhyMeta || {},
    alias: indexHIH73hYLhyMeta?.alias || [],
    redirect: indexHIH73hYLhyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/campaign_settings/[campaign_setting_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91campaign_setting_id_93N0hd1UPpTzMeta?.name ?? undefined,
    meta: _91campaign_setting_id_93N0hd1UPpTzMeta || {},
    alias: _91campaign_setting_id_93N0hd1UPpTzMeta?.alias || [],
    redirect: _91campaign_setting_id_93N0hd1UPpTzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/campaign_settings/[campaign_setting_id].vue").then(m => m.default || m)
  },
  {
    path: campaignsYa0reCUBrnMeta?.path ?? "campaigns",
    children: [
  {
    path: _91campaign_id_930ZmbTc9N6vMeta?.path ?? ":campaign_id()",
    children: [
  {
    name: editQL393HgcAeMeta?.name ?? "admin-campaigns-campaign_id-edit",
    path: editQL393HgcAeMeta?.path ?? "edit",
    meta: editQL393HgcAeMeta || {},
    alias: editQL393HgcAeMeta?.alias || [],
    redirect: editQL393HgcAeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/campaigns/[campaign_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexX6N8XAJWMNMeta?.name ?? "admin-campaigns-campaign_id",
    path: indexX6N8XAJWMNMeta?.path ?? "",
    meta: indexX6N8XAJWMNMeta || {},
    alias: indexX6N8XAJWMNMeta?.alias || [],
    redirect: indexX6N8XAJWMNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/campaigns/[campaign_id]/index.vue").then(m => m.default || m)
  },
  {
    path: outbound_call_transactions2KouUhrV4eMeta?.path ?? "outbound_call_transactions",
    children: [
  {
    path: _91outbound_call_transaction_id_935pJaqAW9YAMeta?.path ?? ":outbound_call_transaction_id()",
    children: [
  {
    name: editTXYhApGbC3Meta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-outbound_call_transaction_id-edit",
    path: editTXYhApGbC3Meta?.path ?? "edit",
    meta: editTXYhApGbC3Meta || {},
    alias: editTXYhApGbC3Meta?.alias || [],
    redirect: editTXYhApGbC3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexp562C8mTtIMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-outbound_call_transaction_id",
    path: indexp562C8mTtIMeta?.path ?? "",
    meta: indexp562C8mTtIMeta || {},
    alias: indexp562C8mTtIMeta?.alias || [],
    redirect: indexp562C8mTtIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91outbound_call_transaction_id_935pJaqAW9YAMeta?.name ?? undefined,
    meta: _91outbound_call_transaction_id_935pJaqAW9YAMeta || {},
    alias: _91outbound_call_transaction_id_935pJaqAW9YAMeta?.alias || [],
    redirect: _91outbound_call_transaction_id_935pJaqAW9YAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id].vue").then(m => m.default || m)
  },
  {
    name: index2Sv5pEcc5XMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions",
    path: index2Sv5pEcc5XMeta?.path ?? "",
    meta: index2Sv5pEcc5XMeta || {},
    alias: index2Sv5pEcc5XMeta?.alias || [],
    redirect: index2Sv5pEcc5XMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/index.vue").then(m => m.default || m)
  },
  {
    name: newuc3cICnmvzMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-new",
    path: newuc3cICnmvzMeta?.path ?? "new",
    meta: newuc3cICnmvzMeta || {},
    alias: newuc3cICnmvzMeta?.alias || [],
    redirect: newuc3cICnmvzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/new.vue").then(m => m.default || m)
  }
],
    name: outbound_call_transactions2KouUhrV4eMeta?.name ?? undefined,
    meta: outbound_call_transactions2KouUhrV4eMeta || {},
    alias: outbound_call_transactions2KouUhrV4eMeta?.alias || [],
    redirect: outbound_call_transactions2KouUhrV4eMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions.vue").then(m => m.default || m)
  }
],
    name: _91campaign_id_930ZmbTc9N6vMeta?.name ?? undefined,
    meta: _91campaign_id_930ZmbTc9N6vMeta || {},
    alias: _91campaign_id_930ZmbTc9N6vMeta?.alias || [],
    redirect: _91campaign_id_930ZmbTc9N6vMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/campaigns/[campaign_id].vue").then(m => m.default || m)
  },
  {
    name: indexLWSTGRllTXMeta?.name ?? "admin-campaigns",
    path: indexLWSTGRllTXMeta?.path ?? "",
    meta: indexLWSTGRllTXMeta || {},
    alias: indexLWSTGRllTXMeta?.alias || [],
    redirect: indexLWSTGRllTXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: newponjh89fhcMeta?.name ?? "admin-campaigns-new",
    path: newponjh89fhcMeta?.path ?? "new",
    meta: newponjh89fhcMeta || {},
    alias: newponjh89fhcMeta?.alias || [],
    redirect: newponjh89fhcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/campaigns/new.vue").then(m => m.default || m)
  }
],
    name: campaignsYa0reCUBrnMeta?.name ?? undefined,
    meta: campaignsYa0reCUBrnMeta || {},
    alias: campaignsYa0reCUBrnMeta?.alias || [],
    redirect: campaignsYa0reCUBrnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/campaigns.vue").then(m => m.default || m)
  },
  {
    path: custom_emailsIo6MyuwsOnMeta?.path ?? "custom_emails",
    children: [
  {
    path: _91custom_email_id_93gdYpOUbtb9Meta?.path ?? ":custom_email_id()",
    children: [
  {
    name: indexHZHjjyW9uvMeta?.name ?? "admin-custom_emails-custom_email_id",
    path: indexHZHjjyW9uvMeta?.path ?? "",
    meta: indexHZHjjyW9uvMeta || {},
    alias: indexHZHjjyW9uvMeta?.alias || [],
    redirect: indexHZHjjyW9uvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/custom_emails/[custom_email_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91custom_email_id_93gdYpOUbtb9Meta?.name ?? undefined,
    meta: _91custom_email_id_93gdYpOUbtb9Meta || {},
    alias: _91custom_email_id_93gdYpOUbtb9Meta?.alias || [],
    redirect: _91custom_email_id_93gdYpOUbtb9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/custom_emails/[custom_email_id].vue").then(m => m.default || m)
  },
  {
    name: indexfFQB30P7TFMeta?.name ?? "admin-custom_emails",
    path: indexfFQB30P7TFMeta?.path ?? "",
    meta: indexfFQB30P7TFMeta || {},
    alias: indexfFQB30P7TFMeta?.alias || [],
    redirect: indexfFQB30P7TFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/custom_emails/index.vue").then(m => m.default || m)
  },
  {
    name: newpqLHhdzZvHMeta?.name ?? "admin-custom_emails-new",
    path: newpqLHhdzZvHMeta?.path ?? "new",
    meta: newpqLHhdzZvHMeta || {},
    alias: newpqLHhdzZvHMeta?.alias || [],
    redirect: newpqLHhdzZvHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/custom_emails/new.vue").then(m => m.default || m)
  }
],
    name: custom_emailsIo6MyuwsOnMeta?.name ?? undefined,
    meta: custom_emailsIo6MyuwsOnMeta || {},
    alias: custom_emailsIo6MyuwsOnMeta?.alias || [],
    redirect: custom_emailsIo6MyuwsOnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/custom_emails.vue").then(m => m.default || m)
  },
  {
    path: dashboardEQeLOEIXIbMeta?.path ?? "dashboard",
    children: [
  {
    name: indexCdx0wI9TxwMeta?.name ?? "admin-dashboard",
    path: indexCdx0wI9TxwMeta?.path ?? "",
    meta: indexCdx0wI9TxwMeta || {},
    alias: indexCdx0wI9TxwMeta?.alias || [],
    redirect: indexCdx0wI9TxwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/dashboard/index.vue").then(m => m.default || m)
  }
],
    name: dashboardEQeLOEIXIbMeta?.name ?? undefined,
    meta: dashboardEQeLOEIXIbMeta || {},
    alias: dashboardEQeLOEIXIbMeta?.alias || [],
    redirect: dashboardEQeLOEIXIbMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/dashboard.vue").then(m => m.default || m)
  },
  {
    path: inquiriesSlliv5ep6GMeta?.path ?? "inquiries",
    children: [
  {
    path: _91inquiry_id_93Oh9XjLhdQPMeta?.path ?? ":inquiry_id()",
    children: [
  {
    name: indexnga7uBzbxpMeta?.name ?? "admin-inquiries-inquiry_id",
    path: indexnga7uBzbxpMeta?.path ?? "",
    meta: indexnga7uBzbxpMeta || {},
    alias: indexnga7uBzbxpMeta?.alias || [],
    redirect: indexnga7uBzbxpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/inquiries/[inquiry_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91inquiry_id_93Oh9XjLhdQPMeta?.name ?? undefined,
    meta: _91inquiry_id_93Oh9XjLhdQPMeta || {},
    alias: _91inquiry_id_93Oh9XjLhdQPMeta?.alias || [],
    redirect: _91inquiry_id_93Oh9XjLhdQPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/inquiries/[inquiry_id].vue").then(m => m.default || m)
  },
  {
    name: indexK6oTw8yu8WMeta?.name ?? "admin-inquiries",
    path: indexK6oTw8yu8WMeta?.path ?? "",
    meta: indexK6oTw8yu8WMeta || {},
    alias: indexK6oTw8yu8WMeta?.alias || [],
    redirect: indexK6oTw8yu8WMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/inquiries/index.vue").then(m => m.default || m)
  },
  {
    name: newoWaE44deOzMeta?.name ?? "admin-inquiries-new",
    path: newoWaE44deOzMeta?.path ?? "new",
    meta: newoWaE44deOzMeta || {},
    alias: newoWaE44deOzMeta?.alias || [],
    redirect: newoWaE44deOzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/inquiries/new.vue").then(m => m.default || m)
  }
],
    name: inquiriesSlliv5ep6GMeta?.name ?? undefined,
    meta: inquiriesSlliv5ep6GMeta || {},
    alias: inquiriesSlliv5ep6GMeta?.alias || [],
    redirect: inquiriesSlliv5ep6GMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/inquiries.vue").then(m => m.default || m)
  },
  {
    path: login6qasTF7S6vMeta?.path ?? "login",
    children: [
  {
    name: indexNn7VgZMENEMeta?.name ?? "admin-login",
    path: indexNn7VgZMENEMeta?.path ?? "",
    meta: indexNn7VgZMENEMeta || {},
    alias: indexNn7VgZMENEMeta?.alias || [],
    redirect: indexNn7VgZMENEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/login/index.vue").then(m => m.default || m)
  }
],
    name: login6qasTF7S6vMeta?.name ?? undefined,
    meta: login6qasTF7S6vMeta || {},
    alias: login6qasTF7S6vMeta?.alias || [],
    redirect: login6qasTF7S6vMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: newfbaBM7VB6vMeta?.name ?? "admin-password-new",
    path: newfbaBM7VB6vMeta?.path ?? "password/new",
    meta: newfbaBM7VB6vMeta || {},
    alias: newfbaBM7VB6vMeta?.alias || [],
    redirect: newfbaBM7VB6vMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/password/new.vue").then(m => m.default || m)
  },
  {
    path: reportseDZA6Nf1iEMeta?.path ?? "reports",
    children: [
  {
    name: indexroO9fM4CmoMeta?.name ?? "admin-reports",
    path: indexroO9fM4CmoMeta?.path ?? "",
    meta: indexroO9fM4CmoMeta || {},
    alias: indexroO9fM4CmoMeta?.alias || [],
    redirect: indexroO9fM4CmoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/reports/index.vue").then(m => m.default || m)
  }
],
    name: reportseDZA6Nf1iEMeta?.name ?? undefined,
    meta: reportseDZA6Nf1iEMeta || {},
    alias: reportseDZA6Nf1iEMeta?.alias || [],
    redirect: reportseDZA6Nf1iEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/reports.vue").then(m => m.default || m)
  },
  {
    path: sms_maskingsCndk3CYoQPMeta?.path ?? "sms_maskings",
    children: [
  {
    path: _91sms_masking_id_934ZOZ95SvS7Meta?.path ?? ":sms_masking_id()",
    children: [
  {
    name: edit2rK0hMRZiYMeta?.name ?? "admin-sms_maskings-sms_masking_id-edit",
    path: edit2rK0hMRZiYMeta?.path ?? "edit",
    meta: edit2rK0hMRZiYMeta || {},
    alias: edit2rK0hMRZiYMeta?.alias || [],
    redirect: edit2rK0hMRZiYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/sms_maskings/[sms_masking_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexnTLPrdzkfxMeta?.name ?? "admin-sms_maskings-sms_masking_id",
    path: indexnTLPrdzkfxMeta?.path ?? "",
    meta: indexnTLPrdzkfxMeta || {},
    alias: indexnTLPrdzkfxMeta?.alias || [],
    redirect: indexnTLPrdzkfxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/sms_maskings/[sms_masking_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91sms_masking_id_934ZOZ95SvS7Meta?.name ?? undefined,
    meta: _91sms_masking_id_934ZOZ95SvS7Meta || {},
    alias: _91sms_masking_id_934ZOZ95SvS7Meta?.alias || [],
    redirect: _91sms_masking_id_934ZOZ95SvS7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/sms_maskings/[sms_masking_id].vue").then(m => m.default || m)
  },
  {
    name: indexSUv7v3VZTDMeta?.name ?? "admin-sms_maskings",
    path: indexSUv7v3VZTDMeta?.path ?? "",
    meta: indexSUv7v3VZTDMeta || {},
    alias: indexSUv7v3VZTDMeta?.alias || [],
    redirect: indexSUv7v3VZTDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/sms_maskings/index.vue").then(m => m.default || m)
  },
  {
    name: newdVh5J2XmVUMeta?.name ?? "admin-sms_maskings-new",
    path: newdVh5J2XmVUMeta?.path ?? "new",
    meta: newdVh5J2XmVUMeta || {},
    alias: newdVh5J2XmVUMeta?.alias || [],
    redirect: newdVh5J2XmVUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/sms_maskings/new.vue").then(m => m.default || m)
  }
],
    name: sms_maskingsCndk3CYoQPMeta?.name ?? undefined,
    meta: sms_maskingsCndk3CYoQPMeta || {},
    alias: sms_maskingsCndk3CYoQPMeta?.alias || [],
    redirect: sms_maskingsCndk3CYoQPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/sms_maskings.vue").then(m => m.default || m)
  },
  {
    path: userspdxOT5yhrrMeta?.path ?? "users",
    children: [
  {
    path: _91user_id_93CuP8u6m6knMeta?.path ?? ":user_id()",
    children: [
  {
    name: edit9tjn17zxxAMeta?.name ?? "admin-users-user_id-edit",
    path: edit9tjn17zxxAMeta?.path ?? "edit",
    meta: edit9tjn17zxxAMeta || {},
    alias: edit9tjn17zxxAMeta?.alias || [],
    redirect: edit9tjn17zxxAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/users/[user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexvXdHnfVN2NMeta?.name ?? "admin-users-user_id",
    path: indexvXdHnfVN2NMeta?.path ?? "",
    meta: indexvXdHnfVN2NMeta || {},
    alias: indexvXdHnfVN2NMeta?.alias || [],
    redirect: indexvXdHnfVN2NMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/users/[user_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91user_id_93CuP8u6m6knMeta?.name ?? undefined,
    meta: _91user_id_93CuP8u6m6knMeta || {},
    alias: _91user_id_93CuP8u6m6knMeta?.alias || [],
    redirect: _91user_id_93CuP8u6m6knMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/users/[user_id].vue").then(m => m.default || m)
  },
  {
    name: indexxEF3ulN06XMeta?.name ?? "admin-users",
    path: indexxEF3ulN06XMeta?.path ?? "",
    meta: indexxEF3ulN06XMeta || {},
    alias: indexxEF3ulN06XMeta?.alias || [],
    redirect: indexxEF3ulN06XMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: newASQDLUfOKiMeta?.name ?? "admin-users-new",
    path: newASQDLUfOKiMeta?.path ?? "new",
    meta: newASQDLUfOKiMeta || {},
    alias: newASQDLUfOKiMeta?.alias || [],
    redirect: newASQDLUfOKiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/users/new.vue").then(m => m.default || m)
  }
],
    name: userspdxOT5yhrrMeta?.name ?? undefined,
    meta: userspdxOT5yhrrMeta || {},
    alias: userspdxOT5yhrrMeta?.alias || [],
    redirect: userspdxOT5yhrrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    path: _91wallet_id_932Fh33jGuivMeta?.path ?? "wallets/:wallet_id()",
    children: [
  {
    name: editoidcdC7VaeMeta?.name ?? "admin-wallets-wallet_id-edit",
    path: editoidcdC7VaeMeta?.path ?? "edit",
    meta: editoidcdC7VaeMeta || {},
    alias: editoidcdC7VaeMeta?.alias || [],
    redirect: editoidcdC7VaeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/wallets/[wallet_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexTRvdwlBROUMeta?.name ?? "admin-wallets-wallet_id",
    path: indexTRvdwlBROUMeta?.path ?? "",
    meta: indexTRvdwlBROUMeta || {},
    alias: indexTRvdwlBROUMeta?.alias || [],
    redirect: indexTRvdwlBROUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/wallets/[wallet_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91wallet_id_932Fh33jGuivMeta?.name ?? undefined,
    meta: _91wallet_id_932Fh33jGuivMeta || {},
    alias: _91wallet_id_932Fh33jGuivMeta?.alias || [],
    redirect: _91wallet_id_932Fh33jGuivMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin/wallets/[wallet_id].vue").then(m => m.default || m)
  }
],
    meta: adminRaRRMfciQLMeta || {},
    alias: adminRaRRMfciQLMeta?.alias || [],
    redirect: adminRaRRMfciQLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: indexDy9fq1OoDTMeta?.name ?? "audit_logs",
    path: indexDy9fq1OoDTMeta?.path ?? "/audit_logs",
    meta: indexDy9fq1OoDTMeta || {},
    alias: indexDy9fq1OoDTMeta?.alias || [],
    redirect: indexDy9fq1OoDTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/audit_logs/index.vue").then(m => m.default || m)
  },
  {
    name: indexSCtNdkExWnMeta?.name ?? "billings",
    path: indexSCtNdkExWnMeta?.path ?? "/billings",
    meta: indexSCtNdkExWnMeta || {},
    alias: indexSCtNdkExWnMeta?.alias || [],
    redirect: indexSCtNdkExWnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/billings/index.vue").then(m => m.default || m)
  },
  {
    name: indexcSfQNZBp6SMeta?.name ?? "call_out-reports",
    path: indexcSfQNZBp6SMeta?.path ?? "/call_out/reports",
    meta: indexcSfQNZBp6SMeta || {},
    alias: indexcSfQNZBp6SMeta?.alias || [],
    redirect: indexcSfQNZBp6SMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/call_out/reports/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93cDQ8nJy7TvMeta?.name ?? "call_recordings-id",
    path: _91id_93cDQ8nJy7TvMeta?.path ?? "/call_recordings/:id()",
    meta: _91id_93cDQ8nJy7TvMeta || {},
    alias: _91id_93cDQ8nJy7TvMeta?.alias || [],
    redirect: _91id_93cDQ8nJy7TvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/call_recordings/[id].vue").then(m => m.default || m)
  },
  {
    name: indexs6RU4LYBB3Meta?.name ?? "campaigns-id",
    path: indexs6RU4LYBB3Meta?.path ?? "/campaigns/:id()",
    meta: indexs6RU4LYBB3Meta || {},
    alias: indexs6RU4LYBB3Meta?.alias || [],
    redirect: indexs6RU4LYBB3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/campaigns/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexlqJG3LiI9gMeta?.name ?? "campaigns",
    path: indexlqJG3LiI9gMeta?.path ?? "/campaigns",
    meta: indexlqJG3LiI9gMeta || {},
    alias: indexlqJG3LiI9gMeta?.alias || [],
    redirect: indexlqJG3LiI9gMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: indexeEj7JLFXoYMeta?.name ?? "dashboard",
    path: indexeEj7JLFXoYMeta?.path ?? "/dashboard",
    meta: indexeEj7JLFXoYMeta || {},
    alias: indexeEj7JLFXoYMeta?.alias || [],
    redirect: indexeEj7JLFXoYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: index3rZpulFL4MMeta?.name ?? "help",
    path: index3rZpulFL4MMeta?.path ?? "/help",
    meta: index3rZpulFL4MMeta || {},
    alias: index3rZpulFL4MMeta?.alias || [],
    redirect: index3rZpulFL4MMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: indexnmUBVPIefKMeta?.name ?? "index",
    path: indexnmUBVPIefKMeta?.path ?? "/",
    meta: indexnmUBVPIefKMeta || {},
    alias: indexnmUBVPIefKMeta?.alias || [],
    redirect: indexnmUBVPIefKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index0ugBIpToT2Meta?.name ?? "inquiry",
    path: index0ugBIpToT2Meta?.path ?? "/inquiry",
    meta: index0ugBIpToT2Meta || {},
    alias: index0ugBIpToT2Meta?.alias || [],
    redirect: index0ugBIpToT2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/inquiry/index.vue").then(m => m.default || m)
  },
  {
    name: editorLbLb4iXDxXMeta?.name ?? "ivr_trees-id-editor",
    path: editorLbLb4iXDxXMeta?.path ?? "/ivr_trees/:id()/editor",
    meta: editorLbLb4iXDxXMeta || {},
    alias: editorLbLb4iXDxXMeta?.alias || [],
    redirect: editorLbLb4iXDxXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/ivr_trees/[id]/editor.vue").then(m => m.default || m)
  },
  {
    name: indexpj4CalD24iMeta?.name ?? "ivr_trees",
    path: indexpj4CalD24iMeta?.path ?? "/ivr_trees",
    meta: indexpj4CalD24iMeta || {},
    alias: indexpj4CalD24iMeta?.alias || [],
    redirect: indexpj4CalD24iMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/ivr_trees/index.vue").then(m => m.default || m)
  },
  {
    name: verify_emailNnfmJV8kghMeta?.name ?? "profiles-user_id-verify_email",
    path: verify_emailNnfmJV8kghMeta?.path ?? "/profiles/:user_id()/verify_email",
    meta: verify_emailNnfmJV8kghMeta || {},
    alias: verify_emailNnfmJV8kghMeta?.alias || [],
    redirect: verify_emailNnfmJV8kghMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/profiles/[user_id]/verify_email.vue").then(m => m.default || m)
  },
  {
    name: welcome_dashboardlhbxNDsihNMeta?.name ?? "profiles-user_id-welcome_dashboard",
    path: welcome_dashboardlhbxNDsihNMeta?.path ?? "/profiles/:user_id()/welcome_dashboard",
    meta: welcome_dashboardlhbxNDsihNMeta || {},
    alias: welcome_dashboardlhbxNDsihNMeta?.alias || [],
    redirect: welcome_dashboardlhbxNDsihNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/profiles/[user_id]/welcome_dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexOC0GlM4he2Meta?.name ?? "profiles",
    path: indexOC0GlM4he2Meta?.path ?? "/profiles",
    meta: indexOC0GlM4he2Meta || {},
    alias: indexOC0GlM4he2Meta?.alias || [],
    redirect: indexOC0GlM4he2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/profiles/index.vue").then(m => m.default || m)
  },
  {
    name: indexzdPxEGyO3WMeta?.name ?? "sub_users-users",
    path: indexzdPxEGyO3WMeta?.path ?? "/sub_users/users",
    meta: indexzdPxEGyO3WMeta || {},
    alias: indexzdPxEGyO3WMeta?.alias || [],
    redirect: indexzdPxEGyO3WMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/sub_users/users/index.vue").then(m => m.default || m)
  },
  {
    name: index5vrcmXDBJcMeta?.name ?? "terms_and_conditions",
    path: index5vrcmXDBJcMeta?.path ?? "/terms_and_conditions",
    meta: index5vrcmXDBJcMeta || {},
    alias: index5vrcmXDBJcMeta?.alias || [],
    redirect: index5vrcmXDBJcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/terms_and_conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexPGlvfaK1RlMeta?.name ?? "users-password-edit",
    path: indexPGlvfaK1RlMeta?.path ?? "/users/password/edit",
    meta: indexPGlvfaK1RlMeta || {},
    alias: indexPGlvfaK1RlMeta?.alias || [],
    redirect: indexPGlvfaK1RlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/users/password/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexiYxm4CmyOnMeta?.name ?? "users-password-new",
    path: indexiYxm4CmyOnMeta?.path ?? "/users/password/new",
    meta: indexiYxm4CmyOnMeta || {},
    alias: indexiYxm4CmyOnMeta?.alias || [],
    redirect: indexiYxm4CmyOnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/users/password/new/index.vue").then(m => m.default || m)
  },
  {
    name: indexgXUJd5SG2NMeta?.name ?? "users-sign_in",
    path: indexgXUJd5SG2NMeta?.path ?? "/users/sign_in",
    meta: indexgXUJd5SG2NMeta || {},
    alias: indexgXUJd5SG2NMeta?.alias || [],
    redirect: indexgXUJd5SG2NMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src911152362/src/pages/users/sign_in/index.vue").then(m => m.default || m)
  }
]